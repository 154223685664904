import React from "react"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Image from "../../components/image-component/image-2023"
export default function DevelopmentAndQA() {
  return (
    <div>
      <Hero
        version="v2"
        className="greeneclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          {
            name: "<span class='hidemobile'>Full Service</span> Team",
            url: "/full-service-team/",
          },
          { name: "Development & QA", url: null },
        ]}
        subHeading={null}
        headingTag="h1"
        heading="Fully built & QAed tests, ready to run."
        description="<p>65+ developers and QA specialists build pixel-perfect tests, fast. Idea to live in 2 weeks.</p>"
        tags={["Test Development", "Cross Platform", "QA Testing"]}
        heroImage="hero_desktop_2.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
      />
      <ClientLogos version="v2" />
      <LeftRightImageText
        mainHeading="High quality. Fast. CRO experts."
        subHeading="Test Development"
        sectionDescription="<p>Our team of 47 developers are CRO specialists with expertise in building complex tests.</p><p>Using an in-house library of 3,500 tests plus a proprietary development platform lets us build your tests in a fraction of the time.</p>"
        // innerClasses="pd-56-tablet-top"
        // wrapperClasses="absolute-image-section"
        innerClasses="absolute-image-section"
        wrapperClasses="pd-56-tablet-top gray-bg"
        id="testdevelopment"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`section_1_desktop.webp`}
              alt={`Research`}
              imgName={[
                {
                  imageName: "section_1_desktop.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "section_1_tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "section_1_mobile.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          //   icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Works with your tech stack."
        subHeading="Cross Platform"
        sectionDescription="<p><strong>CMS:</strong> WordPress, Instapage, Marketo, HubSpot, Unbounce.</p><p><strong>MarTech:</strong> Marketo, HubSpot, Pardot, Salesforce.</p><p><strong>A/B Testing:</strong> VWO, Mutiny, Optimizely, AB Tasty, Adobe Target.</p><p><strong>Web Stack:</strong> HTML, CSS, JavaScript, React, Next, Bootstrap, Vue.<p>"
        imageFolder="how_it_works"
        sectionImage="section_2_desktop.webp"
        innerClasses="reverse pd-112"
        id="crossplatform"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              alt={`Development`}
              fallBackImage={`section_2_desktop.webp`}
              imgName={[
                {
                  imageName: "section_2_desktop.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "section_2_tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "section_2_mobile.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          //   icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Specialized QA team and process."
        subHeading="QA Testing"
        sectionDescription="<p>
        Our rigorous 10-phase, machine- and human-tested QA process ensures every test is pixel-perfect before, during, and after launch.</p>
        <p>186 steps performed by 18 QA team members include verifying code, form behavior, libraries, page load, responsiveness, etc. We also check specifics you provide and test forms according to your requirements.</p>"
        // innerClasses="pd-56-tablet-top"
        // wrapperClasses="absolute-image-section"
        innerClasses="absolute-image-section"
        wrapperClasses="pd-56-tablet-top gray-bg"
        id="qatesting"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              alt={`QA Testing`}
              fallBackImage={`section_3_desktop.webp`}
              imgName={[
                {
                  imageName: "section_3_desktop.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "section_3_tablet.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "section_3_mobile.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          //   icon={<ArrowIcon />}
          type={`cta`}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
